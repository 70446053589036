import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';

const swiper = new Swiper('.ab-stage', {
	modules: [Autoplay, Navigation, Pagination],
	loop: true,
	pagination: {
		el: '.swiper-pagination',
	},
	autoplay: {
		delay: 5000,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

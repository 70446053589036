import L from 'leaflet';

const markerIcon = new URL('../../img/marker-icon.png', import.meta.url);
const marker2xIcon = new URL('../../img/marker-icon@2x.png', import.meta.url);

let count = 1;
const maxBounds = [
	[53, 12],
	[51, 9],
];

function createId() {
	count += 1;
	return 'address-map-' + count;
}

function addMap(contact: Element, latitude: number, longitude: number) {
	const id = createId();
	const container = document.createElement('div');
	container.id = id;
	container.className = 'rounded w-full max-w-[768px] h-[400px] max-h-[80vh]';
	contact.after(container);

	const map = L.map(id, {
		center: [latitude, longitude],
		zoom: 14,
		maxBounds: maxBounds,
		scrollWheelZoom: false,
	});

	L.tileLayer('/osm-tiles/{z}/{x}/{y}.png', {
		attribution: 'Kartendaten &copy; 2013 OpenStreetMap contributors',
	}).addTo(map);

	const html = contact.querySelector('p')?.innerHTML;
	const marker = L.marker([latitude, longitude], {
		icon: L.icon({
			iconUrl: String(markerIcon),
			iconRetinaUrl: String(marker2xIcon),
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [0, -41],
		}),
	});
	marker.addTo(map);
	marker.bindPopup(html).openPopup();
}

function plugin(placeholderEl: HTMLElement) {
	const contact = placeholderEl.closest('.js-ambet-contact');
	const latitude = placeholderEl.dataset.latitude;
	const longitude = placeholderEl.dataset.longitude;
	if (!latitude || !longitude) return;

	const button = document.createElement('button');
	button.type = 'button';
	button.className = 'block bg-gray-200 -mx-1 -my-2 px-3 py-1 rounded';
	button.textContent = 'Auf Karte zeigen …';

	placeholderEl.append(button);

	button.addEventListener('click', (e) => {
		e.preventDefault();

		button.classList.add('hidden');
		addMap(contact, parseFloat(latitude), parseFloat(longitude));
	});
}

[...document.querySelectorAll('.js-ambet-contact-geo')].forEach(plugin);

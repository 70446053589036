import Swiper, {Autoplay, Navigation} from 'swiper';
import focusableSelectors from 'focusable-selectors';

new Swiper('.ab-interviewslider', {
	modules: [Autoplay, Navigation],
	loop: true,
	pagination: {
		el: '.swiper-pagination',
	},
	autoplay: {
		delay: 10000,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

let previouslyFocusedElement: HTMLElement | null = null;
let firstFocusableElement: HTMLElement | null = null;
let lastFocusableElement: HTMLElement | null = null;
let modalActive = false;

/* Generate Modal HTML and append it to the body */
const modalOverlay = document.createElement('div');
modalOverlay.tabIndex = -1;
modalOverlay.className =
	'fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity opacity-0';
modalOverlay.style.display = 'none';

const modalBody = document.createElement('div');
modalBody.className =
	'relative w-full md:w-[90vw] max-w-[980px] max-h-[90vh] overflow-y-auto bg-white rounded shadow-lg';
modalOverlay.append(modalBody);

document.body.append(modalOverlay);

function handle(eventTarget: HTMLElement, selector: string, callback: (element: HTMLElement) => void) {
	if (eventTarget.matches(selector)) {
		callback(eventTarget);
	} else  {
		const closestElement = eventTarget.closest(selector) as HTMLElement;
		if (closestElement) {
			callback(closestElement);
		}
	}
}

document.body.addEventListener('click', (e) => {
	let eventTarget = e.target;
	if (!(eventTarget instanceof HTMLElement)) return;

	handle(eventTarget, '[data-open-interview-modal]', (element) => {
		e.preventDefault();
		const targetId = element.getAttribute(
			'data-open-interview-modal'
		);
		openModal(targetId);
	});

	handle(eventTarget, '[data-close-interview-modal]', () => {
		e.preventDefault();
		closeModal();
	});
});

modalOverlay.addEventListener('click', (e) => {
	if (e.target === modalOverlay) {
		closeModal();
	}
});

window.addEventListener('hashchange', () => {
	const id = window.location.hash.slice(1);
	const element = document.getElementById(id);
	if (element?.hasAttribute('data-interview-modal')) {
		openModal(id);
	} else {
		closeModal();
	}
});

window.addEventListener('keydown', (event) => {
	if (!modalActive) return;

	// Close modal on 'Esc' key press
	if (event.key === 'Escape') {
		closeModal();
	}

	// Trap focus inside the modal
	if (event.key === 'Tab') {
		if (event.shiftKey) {
			/* shift + tab */ if (
				(document.activeElement as HTMLElement) ===
				firstFocusableElement
			) {
				event.preventDefault();
				lastFocusableElement!.focus();
			}
		} /* tab */ else {
			if (
				(document.activeElement as HTMLElement) === lastFocusableElement
			) {
				event.preventDefault();
				firstFocusableElement!.focus();
			}
		}
	}
});

function openModal(id: string) {
	const contentElement = document.getElementById(id);
	if (!contentElement) return;

	modalActive = true;

	modalBody.innerHTML = contentElement.innerHTML;
	modalOverlay.style.display = '';
	modalOverlay.classList.remove('opacity-0');
	window.history.replaceState(null, '', `#${id}`);
	// Trap focus inside the modal and remember the previously focused element
	previouslyFocusedElement = document.activeElement as HTMLElement;
	modalOverlay.focus();

	const focusableElements = Array.from(
		modalBody.querySelectorAll(focusableSelectors.join(','))
	) as HTMLElement[];
	firstFocusableElement = focusableElements[0];
	lastFocusableElement = focusableElements[focusableElements.length - 1];
}

function closeModal() {
	modalOverlay.classList.add('opacity-0');
	modalOverlay.style.display = 'none';
	window.history.replaceState(null, '', window.location.pathname);

	// Return focus to the previously focused element
	if (previouslyFocusedElement) {
		previouslyFocusedElement.focus();
	}
}
